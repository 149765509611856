<template>
  <div>Copy paste me</div>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      error: null,
    }),
  }
</script>
